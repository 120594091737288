import { useEffect } from "react"
import { navigate } from "@reach/router"

const Index = () => {
  useEffect(() => {
    navigate("/work/", {
      replace: true,
      state: {
        tab: "Websites",
      },
    })
  }, [])
  return null
}

export default Index
